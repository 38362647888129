@import "../settings";

// Grid rules and view all button styles are in modules/_relationship-grid.scss.
.relationship {
    padding: $global-padding * 5 0;

    @include breakpoint(medium) {
        padding: $global-padding * 8 0;
    }

    h2 {
        max-width: 20ch;
    }
}
